var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dataInterface" },
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            attrs: {
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              isShowdig: false,
              showCheckbox: false,
              treeTitle: _vm.$t(`cip.plat.sys.dataInterface.title.treeTitle`),
            },
            on: { getNodeClick: _vm.treeNodeClick },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": _vm.$t(
                    `cip.plat.sys.dataInterface.title.indexHeadTitle`
                  ),
                },
                on: {
                  "head-remove": _vm.headRemove,
                  "head-add-tabs": _vm.addOrUpdateHandle,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: {
                  "grid-head-btn": _vm.gridHeadBtn,
                  "search-columns": _vm.searchColumns,
                },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "grid-row-btn": _vm.gridRowBtn,
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "row-remove": _vm.rowRemove,
                  "row-edit": _vm.addOrUpdateHandle,
                  "row-preview": _vm.handlePreview,
                  "gird-handle-select-click": _vm.selectionChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.closeForm
        ? _c("Form", { ref: "Form", on: { close: _vm.closeFormMethod } })
        : _vm._e(),
      _c("Log", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.logVisible,
            expression: "logVisible",
          },
        ],
        ref: "Log",
        on: {
          close: function ($event) {
            _vm.logVisible = false
          },
        },
      }),
      _vm.previewVisible
        ? _c("Preview", { ref: "Preview", on: { close: _vm.closePreview } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }