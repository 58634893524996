var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SNOWS-preview-main flow-form-main" },
    [
      _c(
        "div",
        { staticClass: "SNOWS-common-page-header" },
        [
          _c("el-page-header", {
            attrs: {
              content:
                _vm.$t(`cip.plat.sys.dataInterface.title.previewTitle`) +
                _vm.title,
            },
            on: { back: _vm.goBack },
          }),
          _c(
            "div",
            { staticClass: "options" },
            [
              _c("el-button", { on: { click: _vm.goBack } }, [
                _vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "dataForm",
          staticClass: "main",
          attrs: { "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Request URL" } },
            [
              _c(
                "el-input",
                {
                  attrs: { readonly: "" },
                  model: {
                    value: _vm.url,
                    callback: function ($$v) {
                      _vm.url = $$v
                    },
                    expression: "url",
                  },
                },
                [
                  _c("template", { slot: "prepend" }, [
                    _vm._v(_vm._s(_vm.methods)),
                  ]),
                  _c(
                    "template",
                    { slot: "append" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.testLoading },
                          on: { click: _vm.test },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`cip.plat.sys.dataInterface.btn.TestBtn`)
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm.inputList.length > 0
            ? _c(
                "el-form-item",
                { attrs: { label: "Request Param" } },
                _vm._l(_vm.inputList, function (item, index) {
                  return _c(
                    "el-row",
                    { key: index, staticClass: "mt-10", attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "key",
                              clearable: "",
                              readonly: "",
                            },
                            model: {
                              value: item.field,
                              callback: function ($$v) {
                                _vm.$set(item, "field", $$v)
                              },
                              expression: "item.field",
                            },
                          }),
                        ],
                        1
                      ),
                      item.dataType != "datetime"
                        ? _c(
                            "el-col",
                            { attrs: { span: 18 } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "value", clearable: "" },
                                model: {
                                  value: item.defaultValue,
                                  callback: function ($$v) {
                                    _vm.$set(item, "defaultValue", $$v)
                                  },
                                  expression: "item.defaultValue",
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "el-col",
                            { attrs: { span: 18 } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetime",
                                  format: "yyyy-MM-dd hh:mm:ss",
                                  "value-format": "yyyy-MM-dd hh:mm:ss",
                                  placeholder: "value",
                                  "default-time": "12:00:00",
                                },
                                model: {
                                  value: item.defaultValue,
                                  callback: function ($$v) {
                                    _vm.$set(item, "defaultValue", $$v)
                                  },
                                  expression: "item.defaultValue",
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { staticClass: "value-item", attrs: { label: "Response Body" } },
            [
              _c("el-input", {
                attrs: {
                  readonly: "",
                  type: "textarea",
                  autosize: { minRows: 20 },
                },
                model: {
                  value: _vm.responseData,
                  callback: function ($$v) {
                    _vm.responseData = $$v
                  },
                  expression: "responseData",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }