var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          staticClass: "SNOWS-dialog SNOWS-dialog_center form-script-dialog",
          attrs: {
            "close-on-click-modal": false,
            "lock-scroll": "",
            "append-to-body": "",
            width: "800px",
            "modal-append-to-body": false,
          },
          on: { open: _vm.onOpen },
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "span",
        {
          staticClass: "dialog-title",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c(
            "span",
            [
              _vm._v("数据处理\n      "),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: "小程序不支持在线JS脚本",
                    placement: "top-start",
                  },
                },
                [_c("a", { staticClass: "el-icon-warning-outline" })]
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "form-script-dialog-body" }, [
        _c("div", { staticClass: "right-main" }, [
          _c(
            "div",
            { staticClass: "codeEditor" },
            [
              _c("SNOWSCodeEditor", {
                ref: "CodeEditor",
                attrs: { options: _vm.options },
                model: {
                  value: _vm.text,
                  callback: function ($$v) {
                    _vm.text = $$v
                  },
                  expression: "text",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "tips" }, [
            _c("p", [_vm._v("支持JavaScript的脚本")]),
          ]),
        ]),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [
            _vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn"))),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.onClose()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.defBtn")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }