<template>
  <el-dialog :title="type==='add' ? $t(`cip.plat.sys.dataInterface.title.addDialogTitle`) : $t(`cip.plat.sys.dataInterface.title.editDialogTitle`)" :close-on-click-modal="false"
    :close-on-press-escape="false" :visible.sync="visible" lock-scroll width="600px" append-to-body
    class="SNOWS-dialog SNOWS-dialog_center">
    <el-form ref="fieldForm" :model="dataForm" :rules="dataRule" label-width="80px">
      <el-form-item :label="$t('cip.plat.sys.dataInterface.field.paramName')" prop="field">
        <el-input v-model="dataForm.field" :placeholder="this.$t('cip.cmn.rule.inputWarning')+$t('cip.plat.sys.dataInterface.field.paramName')" />
      </el-form-item>
      <el-form-item :label="$t('cip.plat.sys.dataInterface.field.paramType')" prop="dataType">
        <el-select v-model="dataForm.dataType" :placeholder="$t('cip.cmn.rule.selectWarning')+$t('cip.plat.sys.dataInterface.field.paramType')">
          <el-option v-for="item in options" :key="item.value" :label="item.label"
            :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('cip.plat.sys.dataInterface.field.defaultValue')" prop="defaultValue">
        <el-input v-model="dataForm.defaultValue" :placeholder="this.$t('cip.cmn.rule.inputWarning')+$t('cip.plat.sys.dataInterface.field.defaultValue')" />
      </el-form-item>
      <el-form-item :label="$t('cip.plat.sys.dataInterface.field.isRequired')" prop="required">
        <el-switch v-model="dataForm.required" :active-value="1" :inactive-value="0" />
      </el-form-item>
      <el-form-item :label="$t('cip.plat.sys.dataInterface.field.paramDescription')" prop="fieldName">
        <el-input v-model="dataForm.fieldName" :placeholder="this.$t('cip.cmn.rule.inputWarning')+$t('cip.plat.sys.dataInterface.field.paramDescription')" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">{{$t('cip.cmn.btn.celBtn')}}</el-button>
      <el-button type="primary" @click="dataFormSubmit()">
        {{$t('cip.cmn.btn.defBtn')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    var checkName = (rule, value, callback) => {
      let boo = true
      for (let i = 0; i < this.list.length; i++) {
        if (this.dataForm.field === this.list[i].field && this.dataForm.id !== this.list[i].id) {
          boo = false
          break
        }
      }
      if (boo) {
        callback()
      } else {
        callback(new Error(this.$t('cip.plat.sys.dataInterface.msg.repeat')));
      }
    }
    return {
      visible: false,
      type: 'add',
      dataForm: {
        id: '',
        defaultValue: '',
        field: '',
        dataType: '',
        required: 0,
        fieldName: ''
      },
      list: [],
      options: [
        { label: this.$t('cip.plat.sys.dataInterface.field.char'), value: 'varchar' },
        { label: this.$t('cip.plat.sys.dataInterface.field.int'), value: 'int' },
        { label: this.$t('cip.plat.sys.dataInterface.field.date'), value: 'datetime' },
        { label: this.$t('cip.plat.sys.dataInterface.field.decimal'), value: 'decimal' },
      ],
      dataRule: {
        field: [
          { 
            required: true, 
            message: this.$t('cip.cmn.rule.inputWarning')+this.$t('cip.plat.sys.dataInterface.field.paramName'), 
            trigger: 'blur' 
          },
          { validator: checkName, trigger: 'blur' }
        ],
        dataType: [
          { 
            required: true, 
            message: this.$t('cip.cmn.rule.selectWarning')+this.$t('cip.plat.sys.dataInterface.field.paramType'), 
            trigger: 'blur' 
          }
        ]
      }
    }
  },
  methods: {
    init(dataForm, list) {
      this.visible = true
      this.list = list || []
      this.$nextTick(() => {
        if (dataForm) {
          this.type = 'edit'
          this.dataForm = JSON.parse(JSON.stringify(dataForm))
        } else {
          this.$refs['fieldForm'].resetFields()
          this.dataForm.id = this.sinoma.idGenerator()
          this.type = 'add'
        }
      })
    },
    dataFormSubmit() {
      this.$refs['fieldForm'].validate((valid) => {
        if (valid) {
          this.$emit('addParameter', this.type, this.dataForm)
          this.visible = false
        }
      })
    }
  }
}
</script>
