<template>
  <div class="dataInterface">
    <el-container>
      <CommonTree
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :isShowdig="false"
        :showCheckbox="false"
        @getNodeClick="treeNodeClick"
        :treeTitle="$t(`cip.plat.sys.dataInterface.title.treeTitle`)"
      />
      <el-main>
        <head-layout
          :head-btn-options="headBtnOptions"
          :head-title="$t(`cip.plat.sys.dataInterface.title.indexHeadTitle`)"
          @head-remove="headRemove"
          @head-add-tabs="addOrUpdateHandle"
        ></head-layout>
          <grid-head-layout
            ref="gridHeadLayout"
            :grid-head-btn="gridHeadBtn"
            :search-columns="searchColumns"
            @grid-head-search="gridHeadSearch"
            @grid-head-empty="gridHeadEmpty"
          ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @row-remove="rowRemove"
          @row-edit="addOrUpdateHandle"
          @row-preview="handlePreview"
          @gird-handle-select-click="selectionChange"
        ></grid-layout>
      </el-main>

    </el-container>
    <Form v-if="closeForm" ref="Form" @close="closeFormMethod" />
    <Log v-show="logVisible" ref="Log" @close="logVisible=false" />
    <Preview v-if="previewVisible" ref="Preview" @close="closePreview" />
  </div>
</template>
<script>
import {
  getDataInterfaceList,getTreeData,remove} from '@/api/systemData/dataInterface'
import CommonTree from "@/views/components/com_tree/index"
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import Form from './Form'
import Preview from './Preview'
import Log from './Log'
import {mapGetters} from "vuex";
import {remove as removeTree} from "@/api/system/knowledgeCate"
export default {
  name: 'systemData-dataInterface',
  components: { Form, Preview, Log ,GridLayout,HeadLayout,CommonTree},
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'dictValue'
      },
      page: {
        currentPage: 1,
        pageSize: 20,
        total: 0
      },
      searchTitle: 'dictValue',
      treeLoading: false,
      listLoading: false,
      btnLoading: false,
      categoryId: '',
      treeData: [],
      selectionList: [],
      closeForm: false,
      tableData: [],
      logVisible: false,
      formVisible: false,
      previewVisible: false,
      expandsTree: true,
      refreshTree: true,
    }
  },
  computed: {
    ...mapGetters(["permission"]),
    ids() {
      let ids = this.selectionList.map(item=> item.id);
      return ids.join(",");
    },
    searchColumns() {
      return [
        {
          prop: "fullName",
          span: 4,
          type:"input",
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.plat.sys.dataInterface.field.fullName"),
        },
        {
          prop: "dataType",
          type: 'select',
          dataType: 'string',
          span: 4,
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=DATA_INTERFACE_TYPE",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          placeholder: this.$t(`cip.plat.sys.dataInterface.field.dataType`)
        },
      ]
    },
    tableOptions() {
      return {
        // height: 0,
        menuWidth: 220,
        customAddBtn: true,
        column: [
          {
            label: this.$t('cip.plat.sys.dataInterface.field.fullName'),
            prop: 'fullName',
            align: "left",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.sys.dataInterface.field.enCode'),
            prop: 'enCode',
            width: 340,
            align: "left",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.sys.dataInterface.field.dataType'),
            prop: 'dataType',
            type: 'select',
            dataType: "string",
            align: "center",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=DATA_INTERFACE_TYPE",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            width: 130,
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.sys.dataInterface.field.responseType'),
            prop: 'responseType',
            width: 130,
            align: "center",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.sys.dataInterface.field.requestMethod'),
            prop: 'requestMethod',
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=request_method",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            width: 130,
            align: "center",
            overHidden: true,
          },
        ],
      }
    },
    gridRowBtn() {
      let result = [];
      //新增按钮
      if (this.permission.dataInterface_edit) {
        result.push({
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "row-edit",
          type: "button",
          icon: ""
        });
      }
      if (this.permission.dataInterface_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-remove",
          type: "button",
          icon: ""
        });
        if (this.permission.dataInterface_preview) {
          result.push({
            label: this.$t('cip.plat.sys.dataInterface.btn.previewBtn'),
            emit: "row-preview",
            type: "button",
            icon: ""
          });
        }
        return result

      }

    },
    gridHeadBtn() {
      return []
    },
    headBtnOptions() {
      let result = [];
      //新增按钮
      if (this.permission.dataInterface_add) {
        result.push({
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: 'add',
        });
      }
      if (this.permission.dataInterface_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        });
      }
      return result
    },
  },
  created() {
    getTreeData().then(res=> {
      this.treeData = res.data.data
    })
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    closePreview() {
      this.previewVisible = false
    },
    handlePreview(row) {
      const { id, enabledMark, tenantId, fullName } = row
      if (enabledMark === 0) {
        this.$message({
          type: "error",
          message: this.$t('cip.plat.sys.dataInterface.msg.disabled')
        });
      } else {
        this.previewVisible = true
        this.$nextTick(() => {
          this.$refs.Preview.init(row)
        })
      }
    },
    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    closeFormMethod(isFlush) {
      this.closeForm = false
      if(isFlush){
        this.onLoad(this.page,{categoryId: this.node.id})
      }
    },
    gridHeadSearch(query) {
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    // 左侧树新增
    treeMenuAdd() {
      this.form = {};
      this.form.parentId = this.node.id;
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
    },

    // 左侧树编辑
    treeMenuEdit(data) {
      this.treeReadOnlyFlag = true;
      this.fullscreen = false;
      this.showTreeDialog = true;
      this.form = data;
    },
    selectionChange(list) {
      this.selectionList = list;
    },

    headRemove(){
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          remove(this.ids).then((data) => {
            this.onLoad(this.page);
            this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'));
          });
        });
    },
    // 删除左侧树节点
    treeNodeDel(data, done) {
      if (data.hasChildren) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.hasChildrenWarning'))
        return
      }

      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      }).then(() => {
        removeTree(data.id).then(res => {
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
          this.initTree()
          done()
        })
      }).catch(() => {
      });
    },

    // 点击左侧树节点
    treeNodeClick(node) {
      this.categoryId = node.id;
      this.node = node;
      const {id} = node;
      this.knowledgeTypeId = id;
      this.page.currentPage = 1;
      this.onLoad(this.page, {categoryId: node.id});
    },
    addOrUpdateHandle(id) {
      if(typeof id == 'object'){
        id = id.id
      }else {
        if (!this.node){
          this.$message.warning(this.$t('cip.plat.sys.dataInterface.msg.chooseCategory'))
          return
        }
      }
      this.closeForm = true
      this.$nextTick(() => {
        this.$refs.Form.init(id, this.categoryId)
      })
    },
    toggleTreeExpand(expands) {
      this.refreshTree = false
      this.expandsTree = expands
      this.$nextTick(() => {
        this.refreshTree = true
        this.$nextTick(() => {
          this.$refs.treeBox.setCurrentKey(this.listQuery.categoryId)
        })
      })
    },

    onLoad(page, params = {}) {
      if (this.categoryId != '') {
        params.categoryId = this.categoryId
      }
      this.page = page;
      this.tableLoading = true;
      getDataInterfaceList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    }

  }
}
</script>
<style scoped>
/*.dataInterface {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  position: relative;*/
/*}*/
</style>
