var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SNOWS-preview-main flow-form-main" },
    [
      _c(
        "div",
        { staticClass: "SNOWS-common-page-header" },
        [
          _c("el-page-header", {
            attrs: { content: "" },
            on: { back: _vm.goBack },
          }),
          _c(
            "el-steps",
            {
              class: _vm.dataForm.dataType != 2 ? "elsteps" : "steps",
              attrs: {
                active: _vm.active,
                "finish-status": "success",
                simple: "",
              },
            },
            [
              _c("el-step", {
                attrs: {
                  title: _vm.$t(`cip.plat.sys.dataInterface.title.basicInfo`),
                },
              }),
              _c("el-step", {
                attrs: {
                  title: _vm.$t(`cip.plat.sys.dataInterface.title.dataConf`),
                },
              }),
              _vm.dataForm.dataType != 2
                ? _c("el-step", {
                    attrs: {
                      title: _vm.$t(
                        "cip.plat.sys.dataInterface.title.dataConf"
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "options" },
            [
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.active <= 0 },
                  on: { click: _vm.handlePrevStep },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("cip.plat.sys.dataInterface.field.prev")) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled:
                      _vm.dataForm.dataType != 2
                        ? _vm.active >= 2
                        : _vm.active >= 1,
                  },
                  on: { click: _vm.handleNextStep },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("cip.plat.sys.dataInterface.field.next")) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.btnLoading,
                    disabled:
                      _vm.dataForm.dataType != 2
                        ? _vm.active < 2
                        : _vm.active < 1,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.dataFormSubmit()
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("cip.cmn.btn.defBtn")))]
              ),
              _c("el-button", { on: { click: _vm.goBack } }, [
                _vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.active === 0
        ? _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading",
                },
              ],
              ref: "dataForm",
              staticStyle: { height: "100%" },
              attrs: {
                model: _vm.dataForm,
                rules: _vm.dataRule,
                "label-width": "200px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "baseInfo mt-20",
                      attrs: { span: 14, offset: 5 },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.plat.sys.dataInterface.field.fullName"
                            ),
                            prop: "fullName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "cip.plat.sys.dataInterface.field.fullName"
                              ),
                              maxlength: "50",
                            },
                            model: {
                              value: _vm.dataForm.fullName,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "fullName", $$v)
                              },
                              expression: "dataForm.fullName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.plat.sys.dataInterface.field.enCode"
                            ),
                            prop: "enCode",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "cip.plat.sys.dataInterface.field.enCode"
                              ),
                              maxlength: "50",
                            },
                            model: {
                              value: _vm.dataForm.enCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "enCode", $$v)
                              },
                              expression: "dataForm.enCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.plat.sys.dataInterface.field.categoryId"
                            ),
                            prop: "categoryId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "cip.plat.sys.dataInterface.field.categoryId"
                                ),
                              },
                              model: {
                                value: _vm.dataForm.categoryId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "categoryId", $$v)
                                },
                                expression: "dataForm.categoryId",
                              },
                            },
                            _vm._l(_vm.dataOptions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.dictValue,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.dataForm.checkType === 2
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "ipAddress" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "cip.plat.sys.dataInterface.msg.ipAddress"
                                  ),
                                },
                                model: {
                                  value: _vm.dataForm.ipAddress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "ipAddress", $$v)
                                  },
                                  expression: "dataForm.ipAddress",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.plat.sys.dataInterface.field.type"
                            ),
                            prop: "dataType",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.onDataTypeChange },
                              model: {
                                value: _vm.dataForm.dataType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "dataType", $$v)
                                },
                                expression: "dataForm.dataType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "cip.plat.sys.dataInterface.field.staticData"
                                    )
                                  ) + " "
                                ),
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "cip.plat.sys.dataInterface.field.SQLOperations"
                                    )
                                  ) + " "
                                ),
                              ]),
                              _c("el-radio", { attrs: { label: 3 } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "cip.plat.sys.dataInterface.field.APIOperations"
                                    )
                                  ) + " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.dataForm.dataType === 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "cip.plat.sys.dataInterface.field.action"
                                ),
                                prop: "requestMethod",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: {
                                    change: function ($event) {
                                      return _vm.onMethodChange($event, "sql")
                                    },
                                  },
                                  model: {
                                    value: _vm.dataForm.requestMethod,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "requestMethod",
                                        $$v
                                      )
                                    },
                                    expression: "dataForm.requestMethod",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "3" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "cip.plat.sys.dataInterface.field.Query"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "cip.plat.sys.dataInterface.field.Add"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("el-radio", { attrs: { label: "2" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "cip.plat.sys.dataInterface.field.Modify"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("el-radio", { attrs: { label: "4" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "cip.plat.sys.dataInterface.field.Delete"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.plat.sys.dataInterface.field.sort"
                            ),
                            prop: "sortCode",
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              min: 0,
                              max: 999999,
                              "controls-position": "right",
                            },
                            model: {
                              value: _vm.dataForm.sortCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "sortCode", $$v)
                              },
                              expression: "dataForm.sortCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.plat.sys.dataInterface.field.status"
                            ),
                            prop: "enabledMark",
                          },
                        },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.dataForm.enabledMark,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "enabledMark", $$v)
                              },
                              expression: "dataForm.enabledMark",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.plat.sys.dataInterface.field.description"
                            ),
                            prop: "description",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", rows: 3 },
                            model: {
                              value: _vm.dataForm.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "description", $$v)
                              },
                              expression: "dataForm.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.active === 1 && _vm.dataForm.dataType === 1
        ? _c("div", { staticClass: "config" }, [
            _c(
              "div",
              { staticClass: "tableData" },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      filterable: "",
                      placeholder: _vm.$t(
                        "cip.plat.sys.dataInterface.field.changeDB"
                      ),
                    },
                    on: { change: _vm.handleSelectTable },
                    model: {
                      value: _vm.dataForm.dbLinkId,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "dbLinkId", $$v)
                      },
                      expression: "dataForm.dbLinkId",
                    },
                  },
                  _vm._l(_vm.dbOptions, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.linkName, value: item.id },
                    })
                  }),
                  1
                ),
                _c(
                  "div",
                  { staticClass: "box" },
                  [
                    _c("el-tree", {
                      attrs: {
                        data: _vm.treeData,
                        "node-key": "index",
                        props: _vm.defaultProps,
                      },
                      on: { "node-click": _vm.handleNodeClick },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "detail" }, [
              _c("div", { staticClass: "middle-pane" }, [
                _c("div", { staticClass: "right-pane-list" }, [
                  _c("div", { staticClass: "cap" }, [
                    _c(
                      "span",
                      { attrs: { slot: "label" }, slot: "label" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("cip.plat.sys.dataInterface.field.SQL")
                          ) + "\n              "
                        ),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: _vm.$t(
                                "cip.plat.sys.dataInterface.field.SQL"
                              ),
                              placement: "top",
                            },
                          },
                          [_c("a", { staticClass: "el-icon-warning-outline" })]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { float: "right", cursor: "pointer" } },
                      [
                        _c(
                          "el-dropdown",
                          [
                            _c(
                              "span",
                              {
                                staticClass: "el-dropdown-link",
                                attrs: { title: "111" },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "cip.plat.sys.dataInterface.field.systemVariables"
                                      )
                                    )
                                ),
                                _c("i", {
                                  staticClass:
                                    "el-icon-arrow-down el-icon--right",
                                }),
                              ]
                            ),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  { attrs: { disabled: "" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "cip.plat.sys.dataInterface.msg.msg"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c("el-dropdown-item", {
                                  attrs: { divided: "" },
                                }),
                                _vm._l(
                                  _vm.sysVariableList,
                                  function (item, index) {
                                    return _c(
                                      "el-dropdown-item",
                                      { key: index },
                                      [
                                        _c(
                                          "div",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleSysNodeClick(
                                                  item.value
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.value)),
                                            ]),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  float: "right",
                                                  color: "#8492a6",
                                                  "padding-left": "10px",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.tips))]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "list" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "dataForm",
                          staticStyle: { "padding-top": "0px" },
                          attrs: {
                            model: _vm.dataForm,
                            rules: _vm.dataRule,
                            "label-width": "100px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "0", prop: "query" } },
                            [
                              _c(
                                "div",
                                { staticClass: "sql-box" },
                                [
                                  _c("SQLEditor", {
                                    ref: "SQLEditorRef",
                                    attrs: { options: _vm.sqlOptions },
                                    model: {
                                      value: _vm.dataForm.query,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "query", $$v)
                                      },
                                      expression: "dataForm.query",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "right-pane" }, [
              _c("div", { staticClass: "right-pane-list" }, [
                _c("div", { staticClass: "cap" }, [
                  _c(
                    "span",
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: _vm.$t(
                              "cip.plat.sys.dataInterface.field.reviceMeth"
                            ),
                            placement: "top",
                          },
                        },
                        [_c("a", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "list" },
                  [
                    _c(
                      "el-table",
                      {
                        ref: "dragTable",
                        attrs: {
                          data: _vm.requestParameters,
                          "row-key": "id",
                          size: "mini",
                          height: "100%",
                        },
                      },
                      [
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              align: "center",
                              label: _vm.$t(
                                "cip.plat.sys.dataInterface.field.drag"
                              ),
                              width: "50",
                            },
                          },
                          [
                            [
                              _c("i", {
                                staticClass:
                                  "drag-handler icon-ym icon-ym-darg",
                                staticStyle: {
                                  cursor: "move",
                                  "font-size": "20px",
                                },
                                attrs: {
                                  title: _vm.$t(
                                    "cip.plat.sys.dataInterface.field.clickDrag"
                                  ),
                                },
                              }),
                            ],
                          ],
                          2
                        ),
                        _c("el-table-column", {
                          attrs: {
                            prop: "field",
                            label: _vm.$t(
                              "cip.plat.sys.dataInterface.field.paramName"
                            ),
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleItemClick(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "required-sign" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.required ? "*" : ""
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(scope.row.field) +
                                            _vm._s(
                                              scope.row.fieldName
                                                ? "(" +
                                                    scope.row.fieldName +
                                                    ")"
                                                : ""
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1925480305
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "dataType",
                            label: _vm.$t(
                              "cip.plat.sys.dataInterface.field.paramType"
                            ),
                            width: "70",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.dataType === "varchar"
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "cip.plat.sys.dataInterface.field.char"
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    scope.row.dataType === "int"
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "cip.plat.sys.dataInterface.field.int"
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    scope.row.dataType === "datetime"
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "cip.plat.sys.dataInterface.field.date"
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    scope.row.dataType === "decimal"
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "cip.plat.sys.dataInterface.field.decimal"
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    scope.row.dataType === "bigint"
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "cip.plat.sys.dataInterface.field.bigint"
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    scope.row.dataType === "text"
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "cip.plat.sys.dataInterface.field.text"
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            40623460
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t(
                              "cip.plat.sys.dataInterface.field.make"
                            ),
                            width: "70",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-button", {
                                      attrs: {
                                        type: "text",
                                        icon: "el-icon-edit-outline",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addOrUpdateHandle(
                                            scope.row
                                          )
                                        },
                                      },
                                    }),
                                    _c("el-button", {
                                      staticClass: "SNOWS-table-delBtn",
                                      attrs: {
                                        type: "text",
                                        icon: "el-icon-delete",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeParameter(
                                            scope.$index
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            534491769
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "table-actions",
                    on: {
                      click: function ($event) {
                        return _vm.addOrUpdateHandle()
                      },
                    },
                  },
                  [
                    _c(
                      "el-button",
                      { attrs: { type: "text", icon: "el-icon-plus" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("cip.plat.sys.dataInterface.field.addParam")
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.active === 2
        ? _c("div", { staticClass: "jsStaticData" }, [
            _c(
              "div",
              { staticClass: "json-box" },
              [
                _c("SNOWSCodeEditor", {
                  ref: "CodeEditor",
                  attrs: { options: _vm.options },
                  model: {
                    value: _vm.text,
                    callback: function ($$v) {
                      _vm.text = $$v
                    },
                    expression: "text",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "jsTips" }, [
              _c("p", [
                _vm._v(_vm._s(_vm.$t("cip.plat.sys.dataInterface.msg.JSmsg"))),
              ]),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("cip.plat.sys.dataInterface.msg.JSmsg1"))),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.active === 1 && _vm.dataForm.dataType === 2
        ? _c(
            "div",
            { staticClass: "staticData" },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  attrs: {
                    model: _vm.dataForm,
                    rules: _vm.dataRule,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0", prop: "query" } },
                    [
                      _c(
                        "div",
                        { staticClass: "json-box" },
                        [
                          _c("JSONEditor", {
                            ref: "JSONEditorRef",
                            attrs: { options: _vm.jsonOptions },
                            model: {
                              value: _vm.dataForm.query,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "query", $$v)
                              },
                              expression: "dataForm.query",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.active === 1 && _vm.dataForm.dataType === 3
        ? _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.dataForm,
                rules: _vm.dataRule,
                "label-width": "110px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "mt-20 baseInfo",
                      attrs: { span: 14, offset: 5 },
                    },
                    [
                      _c(
                        "snows-form-tip-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.plat.sys.dataInterface.field.interfaceType"
                            ),
                            prop: "requestMethod",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.onMethodChange($event, "api")
                                },
                              },
                              model: {
                                value: _vm.dataForm.requestMethod,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "requestMethod", $$v)
                                },
                                expression: "dataForm.requestMethod",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "6" } }, [
                                _vm._v("GET"),
                              ]),
                              _c("el-radio", { attrs: { label: "7" } }, [
                                _vm._v("POST"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "snows-form-tip-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.plat.sys.dataInterface.field.interfacePath"
                            ),
                            prop: "path",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "cip.plat.sys.dataInterface.field.interfacePath"
                                ),
                              },
                              model: {
                                value: _vm.dataForm.path,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "path", $$v)
                                },
                                expression: "dataForm.path",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "el-icon-plus",
                                  attrs: { slot: "append" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addHeaders()
                                    },
                                  },
                                  slot: "append",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "cip.plat.sys.dataInterface.field.addHeader"
                                      )
                                    ) + "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._l(_vm.requestHeaders, function (item, index) {
                            return _c(
                              "el-row",
                              { key: item.index, staticClass: "mt-10" },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 10 } },
                                  [
                                    _c("el-autocomplete", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        "fetch-suggestions": _vm.querySearch,
                                        placeholder: "key",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.field,
                                        callback: function ($$v) {
                                          _vm.$set(item, "field", $$v)
                                        },
                                        expression: "item.field",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 10, offset: 1 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "value",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.defaultValue,
                                        callback: function ($$v) {
                                          _vm.$set(item, "defaultValue", $$v)
                                        },
                                        expression: "item.defaultValue",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 2, offset: 1 } },
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        type: "danger",
                                        icon: "el-icon-close",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeHeaders(index)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                      _c(
                        "snows-form-tip-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.plat.sys.dataInterface.field.param"
                            ),
                            "tip-label": _vm.$t(
                              "cip.plat.sys.dataInterface.field.reviceMeth"
                            ),
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "el-icon-plus",
                              attrs: { size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.addOrUpdateHandle()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "cip.plat.sys.dataInterface.field.addParam"
                                  )
                                ) + "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "parameterList" },
                        [
                          _c(
                            "el-table",
                            {
                              ref: "dragTable",
                              attrs: {
                                data: _vm.requestParameters,
                                "row-key": "id",
                                size: "mini",
                              },
                            },
                            [
                              _c(
                                "el-table-column",
                                {
                                  attrs: {
                                    align: "center",
                                    label: _vm.$t(
                                      "cip.plat.sys.dataInterface.field.drag"
                                    ),
                                    width: "50",
                                  },
                                },
                                [
                                  [
                                    _c("i", {
                                      staticClass:
                                        "drag-handler icon-ym icon-ym-darg",
                                      staticStyle: {
                                        cursor: "move",
                                        "font-size": "20px",
                                      },
                                      attrs: {
                                        title: _vm.$t(
                                          "cip.plat.sys.dataInterface.field.clickDrag"
                                        ),
                                      },
                                    }),
                                  ],
                                ],
                                2
                              ),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "field",
                                  label: _vm.$t(
                                    "cip.plat.sys.dataInterface.field.paramName"
                                  ),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("p", [
                                            _c(
                                              "span",
                                              { staticClass: "required-sign" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.required
                                                      ? "*"
                                                      : ""
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(scope.row.field) +
                                                _vm._s(
                                                  scope.row.fieldName
                                                    ? "(" +
                                                        scope.row.fieldName +
                                                        ")"
                                                    : ""
                                                ) +
                                                "\n                "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3226089087
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "dataType",
                                  label: _vm.$t(
                                    "cip.plat.sys.dataInterface.field.paramType"
                                  ),
                                  width: "70",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.dataType === "varchar"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "cip.plat.sys.dataInterface.field.char"
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                          scope.row.dataType === "int"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "cip.plat.sys.dataInterface.field.int"
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                          scope.row.dataType === "datetime"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "cip.plat.sys.dataInterface.field.date"
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                          scope.row.dataType === "decimal"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "cip.plat.sys.dataInterface.field.decimal"
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                          scope.row.dataType === "bigint"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "cip.plat.sys.dataInterface.field.bigint"
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                          scope.row.dataType === "text"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "cip.plat.sys.dataInterface.field.text"
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  40623460
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "defaultValue",
                                  label: _vm.$t(
                                    "cip.plat.sys.dataInterface.field.defaultValue"
                                  ),
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t(
                                    "cip.plat.sys.dataInterface.field.make"
                                  ),
                                  width: "70",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-button", {
                                            attrs: {
                                              type: "text",
                                              icon: "el-icon-edit-outline",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addOrUpdateHandle(
                                                  scope.row
                                                )
                                              },
                                            },
                                          }),
                                          _c("el-button", {
                                            staticClass: "SNOWS-table-delBtn",
                                            attrs: {
                                              type: "text",
                                              icon: "el-icon-delete",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeParameter(
                                                  scope.$index
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  534491769
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("FieldForm", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.fieldFormVisible,
            expression: "fieldFormVisible",
          },
        ],
        ref: "fieldForm",
        on: { addParameter: _vm.addParameter },
      }),
      _c("form-script", {
        attrs: {
          visible: _vm.formScriptVisible,
          value: this.dataForm.dataProcessing,
        },
        on: {
          "update:visible": function ($event) {
            _vm.formScriptVisible = $event
          },
          updateScript: _vm.updateScript,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }