var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "SNOWS-dialog SNOWS-dialog_center",
      attrs: {
        title:
          _vm.type === "add"
            ? _vm.$t(`cip.plat.sys.dataInterface.title.addDialogTitle`)
            : _vm.$t(`cip.plat.sys.dataInterface.title.editDialogTitle`),
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        visible: _vm.visible,
        "lock-scroll": "",
        width: "600px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "fieldForm",
          attrs: {
            model: _vm.dataForm,
            rules: _vm.dataRule,
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("cip.plat.sys.dataInterface.field.paramName"),
                prop: "field",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder:
                    this.$t("cip.cmn.rule.inputWarning") +
                    _vm.$t("cip.plat.sys.dataInterface.field.paramName"),
                },
                model: {
                  value: _vm.dataForm.field,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "field", $$v)
                  },
                  expression: "dataForm.field",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("cip.plat.sys.dataInterface.field.paramType"),
                prop: "dataType",
              },
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder:
                      _vm.$t("cip.cmn.rule.selectWarning") +
                      _vm.$t("cip.plat.sys.dataInterface.field.paramType"),
                  },
                  model: {
                    value: _vm.dataForm.dataType,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "dataType", $$v)
                    },
                    expression: "dataForm.dataType",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("cip.plat.sys.dataInterface.field.defaultValue"),
                prop: "defaultValue",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder:
                    this.$t("cip.cmn.rule.inputWarning") +
                    _vm.$t("cip.plat.sys.dataInterface.field.defaultValue"),
                },
                model: {
                  value: _vm.dataForm.defaultValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "defaultValue", $$v)
                  },
                  expression: "dataForm.defaultValue",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("cip.plat.sys.dataInterface.field.isRequired"),
                prop: "required",
              },
            },
            [
              _c("el-switch", {
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.dataForm.required,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "required", $$v)
                  },
                  expression: "dataForm.required",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t(
                  "cip.plat.sys.dataInterface.field.paramDescription"
                ),
                prop: "fieldName",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder:
                    this.$t("cip.cmn.rule.inputWarning") +
                    _vm.$t("cip.plat.sys.dataInterface.field.paramDescription"),
                },
                model: {
                  value: _vm.dataForm.fieldName,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "fieldName", $$v)
                  },
                  expression: "dataForm.fieldName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.dataFormSubmit()
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("cip.cmn.btn.defBtn")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }