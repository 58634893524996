<template>
  <div class="SNOWS-preview-main flow-form-main ">
    <div class="SNOWS-common-page-header">
      <el-page-header @back="goBack" :content="$t(`cip.plat.sys.dataInterface.title.previewTitle`)+title" />
      <div class="options">
        <el-button @click="goBack">{{$t('cip.cmn.btn.celBtn')}}</el-button>
      </div>
    </div>
    <el-form ref="dataForm" class="main" label-position="top" v-loading="formLoading">
      <el-form-item label="Request URL">
        <el-input readonly v-model="url">
          <template slot="prepend">{{methods}}</template>
          <template slot="append">
            <el-button type="primary" @click="test" :loading="testLoading">{{ $t(`cip.plat.sys.dataInterface.btn.TestBtn`) }}</el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="Request Param" v-if="inputList.length>0">
        <el-row v-for="(item, index) in inputList" :key="index" :gutter="20" class="mt-10">
          <el-col :span="6">
            <el-input v-model="item.field" placeholder="key" clearable readonly />
          </el-col>
          <el-col :span="18" v-if="item.dataType != 'datetime'" >
            <el-input v-model="item.defaultValue" placeholder="value" clearable />
          </el-col>

          <el-col :span="18" v-else="item.dataType == 'datatime">
            <el-date-picker
              v-model="item.defaultValue"
              type="datetime"
              format="yyyy-MM-dd hh:mm:ss"
              value-format="yyyy-MM-dd hh:mm:ss"
              placeholder="value"
              default-time="12:00:00">
            </el-date-picker>
          </el-col>

        </el-row>
      </el-form-item>
      <el-form-item label="Response Body" class="value-item">
        <el-input readonly v-model="responseData" type="textarea" :autosize="{ minRows: 20 }" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getDataInterfaceParam, getDataInterfaceRes } from '@/api/systemData/dataInterface'

export default {
  data() {
    return {
      title: '',
      formLoading: false,
      testLoading: false,
      responseData: '',
      url: '',
      options: {
        readOnly: true,
        language: 'json'
      },
      inputList: [],
      tenantId: '',
      methods:'',
    }
  },
  methods: {
    test() {
      this.testLoading = true
      let query = {
        paramList: this.inputList,
        tenantId: this.tenantId,
        origin: 'preview'
      }
      getDataInterfaceRes(this.id, query).then(res => {
        this.testLoading = false
        let data = res.data
        this.responseData = JSON.stringify(data, null, 4)
      }).catch(() => {
        this.testLoading = false
      })
    },
    goBack() {
      this.$emit('close')
    },
    init(row) {
      this.methods = row.requestMethod;
      this.id = row.id || ''
      this.tenantId = row.tenantId || ''
      this.title = row.fullName || ''
      this.formLoading = true
      this.responseData = ''
      this.$nextTick(() => {
        // const prefix = this.define.comUrl === '/dev' ? window.location.origin : ''
        const prefix  = window.location.origin
        this.url = `${prefix}/api/sinoma-desk/dataInterface/${row.id}/Actions/Preview` + (row.tenantId ? '?tenantId=' + row.tenantId : '')
        getDataInterfaceParam(this.id).then(res => {
          this.inputList = res.data.data
          this.formLoading = false
        }).catch(() => {
          this.formLoading = false
        })
      })
    },
  }
}
</script>
<style>
.dataInterface {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
